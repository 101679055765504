(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/normalize/assets/javascripts/helpers/get-game-status.js') >= 0) return;  svs.modules.push('/components/marketplace-data/normalize/assets/javascripts/helpers/get-game-status.js');
"use strict";


const {
  GAME_STATUS_FINISHED,
  GAME_STATUS_NOT_ENOUGH_SOLD,
  GAME_STATUS_OPEN,
  GAME_STATUS_SUBMITTABLE,
  GAME_STATUS_SUBMITTED,
  GAME_STATUS_DRAW_CLOSED,
  GAME_STATUS_SOLD_OUT
} = svs.components.marketplaceData.gamesConstants;
const getGameStatus = game => {
  const now = new Date();
  switch (true) {
    case Boolean(game.status):
      return game.status;
    case game.isFinished:
      return GAME_STATUS_FINISHED;
    case game.isSubmitted:
      return GAME_STATUS_SUBMITTED;
    case now > game.drawCloseTime:
      return GAME_STATUS_DRAW_CLOSED;
    case game.isSoldOut:
      return GAME_STATUS_SOLD_OUT;
    case now < game.buyStop && !game.isSoldOut:
      return GAME_STATUS_OPEN;
    case game.isSubmittable === false:
      return GAME_STATUS_NOT_ENOUGH_SOLD;
    case game.isSubmittable === true:
      return GAME_STATUS_SUBMITTABLE;
    default:
      return 'Okänd status';
  }
};
setGlobal('svs.components.marketplaceData.normalize.helpers.getGameStatus', getGameStatus);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/normalize/assets/javascripts/map-game.js') >= 0) return;  svs.modules.push('/components/marketplace-data/normalize/assets/javascripts/map-game.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  getProdName
} = svs.components.lbUtils.sportProducts;
const {
  helpers
} = svs.components.marketplaceData.normalize;
const {
  MAX_FRACTIONS_PER_GAME
} = svs.components.marketplaceData.gamesConstants;
const {
  GAME_STATUS_FINISHED,
  GAME_STATUS_SUBMITTED
} = svs.components.marketplaceData.gamesConstants;
function mapGame(customerId, game) {
  const nGame = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
    subProductId: game.subProductId || null,
    activatedDrawId: game.activatedDrawId,
    containerDescription: game.description,
    correctResults: game.correctResults,
    containerId: game.containerId,
    currentUserNumberOfShares: game.currentUserNumberOfShares,
    description: game.activatedDrawDescription
  }, game.drawName ? {
    drawName: game.drawName
  } : {}), {}, {
    groupId: game.groupId,
    groupName: game.groupName,
    fractions: _normalizeFractions(customerId ? [{
      customerId,
      numberOfShares: game.currentUserNumberOfShares
    }] : game.shares),
    name: game.name,
    maxFractions: game.maxShares,
    maxFractionsPerMember: game.memberMaxShares,
    ownerName: game.gameOwnerName || game.bettor || game.bettorName || game.editorName,
    preliminaryRowExists: game.preliminaryRowExists,
    productName: getProdName(game.productId),
    productId: game.productId,
    retailerFee: game.retailerFee
  }, game.commissionPercent ? {
    commissionPercent: game.commissionPercent
  } : {}), {}, {
    isFinished: Boolean(game.completedDateTime),
    isSubmitted: Boolean(game.wagerSerial),
    wagerSerial: game.wagerSerial,
    drawNumber: game.drawNumber,
    templateId: game.containerId,
    isSubmittable: game.isSubmittable,
    isAllowedToSubmit: game.isAllowedToSubmit,
    soldShares: game === null || game === void 0 ? void 0 : game.soldShares,
    fractionsCount: game.soldShares ? game.soldShares : countFractions(game.shares),
    isSoldOut: (game.soldShares ? game.soldShares : countFractions(game.shares)) === (game.maxShares ? game.maxShares : MAX_FRACTIONS_PER_GAME)
  }, game.wagerAmount ? {
    wagerAmount: parseInt(game.wagerAmount, 10)
  } : {}), game.totalBalance ? {
    totalBalance: parseInt(game.totalBalance, 10)
  } : {}), game.sharePrice ? {
    fractionPrice: parseInt(game.sharePrice, 10)
  } : {}), game.shareCommittedPrice ? {
    fractionCommittedPrice: Number(game.shareCommittedPrice.replace(',', '.'))
  } : {}), game.buyinCloseTime ? {
    buyStop: new Date(game.buyinCloseTime)
  } : {}), game.regClose ? {
    drawCloseTime: new Date(game.regClose)
  } : {}), game.completedDateTime ? {
    completedDateTime: new Date(game.completedDateTime)
  } : {}), game.copies ? {
    copies: game.copies
  } : {}), game.copiesCloseTime ? {
    copiesCloseTime: game.copiesCloseTime
  } : {});
  const status = helpers.getGameStatus(nGame);
  const wagerFields = game.isRetail ? parseWagerFields(status, game) : {};
  return _objectSpread(_objectSpread(_objectSpread({}, nGame), wagerFields), {}, {
    status
  });
}
const parseWagerFields = (status, game) => {
  if (game.activatedDrawStatus === 4 || [GAME_STATUS_FINISHED, GAME_STATUS_SUBMITTED].indexOf(status) > -1) {
    const {
      soldShares,
      wagerAmount,
      shareCommittedPrice,
      wagerSharePrice
    } = game;
    return {
      soldShares,
      wagerAmount: wagerAmount ? Number(wagerAmount.replace(',', '.')) : null,
      shareCommittedPrice: shareCommittedPrice ? Number(shareCommittedPrice.replace(',', '.')) : null,
      wagerSharePrice: wagerSharePrice ? Number(wagerSharePrice.replace(',', '.')) : null,
      shareCommission: calculateShareCommission(shareCommittedPrice, wagerSharePrice)
    };
  }
  return {};
};
const calculateShareCommission = (shareCommittedPrice, wagerSharePrice) => {
  try {
    return shareCommittedPrice && wagerSharePrice ? Math.round((Number(shareCommittedPrice.replace(',', '.')) - Number(wagerSharePrice.replace(',', '.'))) * 100) / 100 : null;
  } catch (e) {
    return null;
  }
};
const countFractions = function () {
  let fractions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return fractions.reduce((acc, fraction) => acc + fraction.numberOfShares, 0);
};
const _normalizeFractions = function () {
  let fractions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const normalizedFractions = {};
  fractions.forEach(fraction => {
    normalizedFractions[fraction.customerId] = fraction.numberOfShares;
  });
  return normalizedFractions;
};
setGlobal('svs.components.marketplaceData.normalize.mapGame', mapGame);

 })(window);
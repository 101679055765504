(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/normalize/assets/javascripts/normalize-games.js') >= 0) return;  svs.modules.push('/components/marketplace-data/normalize/assets/javascripts/normalize-games.js');
"use strict";


const {
  mapGame
} = svs.components.marketplaceData.normalize;
const normalizeGames = function (customerId) {
  for (var _len = arguments.length, games = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    games[_key - 1] = arguments[_key];
  }
  return games.map(game => {
    if (isNaN(parseFloat(game.retailerFee)) && game.retailerFee !== undefined) {
      throw Error("retailerFee has an invalid value: ".concat(game.retailerFee));
    }
    return mapGame(customerId, game);
  });
};
setGlobal('svs.components.marketplaceData.normalize.normalizeGames', normalizeGames);

 })(window);